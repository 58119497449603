import { Box, CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import { React } from "react";
import Header from "./Components/Header";
import Gebeta from "./Components/images/gebeta.svg";
import Gebetasmall from "./Components/images/gebetasmall.svg";
import Background from "./Components/images/background.png";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Terms } from "./pages/terms";
import { Privacy } from "./pages/privacy";

function App() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const gebetaImg = isMatch ? Gebetasmall : Gebeta;

  return (
    <Box>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
