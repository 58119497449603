import React from "react";
import {
  Button,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import "./App.css";
import Gebeta from "./images/gebeta.svg";
import Gebetasmall from "./images/gebetasmall.svg";
import Background from "./images/background.png";
import Logo from "./images/sewasewlogo.svg";
import Streaming from "./images/streaming.svg";
import { Box, Modal } from "@mui/material";
import BasicModal from "./Register";
import { Link } from "react-router-dom";

function Header() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const Text = (
    <Container>
      <div className="mdbig">
        <div className="mdbigtext">ለደስታ ለፌሽታ </div>
        <div className="mdbigtext">ሰዋሰው ገበታ</div>
      </div>

      <div className="mdsmall">Get unlimited access to All Ethiopian</div>
      <div className="mdsmall">music and Listen on your Mobile</div>

      <img className="mdstreaming" src={Streaming}></img>
    </Container>
  );

  const Footer = (
    <>
      <Typography
        color="white"
        paddingTop="5"
        paddingLeft="85px"
        fontSize="20px"
      >
        Register here >>
      </Typography>
      <Button
        sx={{
          color: "#BBBBBB",
          fontSize: 12,
          paddingRight: 0,
          fontWeight: 300,
        }}
      >
        <Button
          sx={{
            color: "#F5F5F5",
            fontWeight: 300,
          }}
          component={Link}
          to="/terms"
        >
          Terms of Service
        </Button>
      </Button>
      <Button
        sx={{
          color: "#BBBBBB",
          fontSize: 12,
          paddingRight: 4,
          fontWeight: 300,
        }}
      >
        <Button
          sx={{
            color: "#F5F5F5",
            fontWeight: 300,
          }}
          component={Link}
          to="/privacy"
        >
          Privacy Policy
        </Button>
      </Button>
    </>
  );
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        backgroundImage: {
          xs: `url(${Gebetasmall}), url(${Background})`,
          sm: `url(${Gebeta}), url(${Background})`,
        },
        backgroundPosition: `center center, center center`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover, cover",
        // objectFit: "contain",
      }}
    >
      {/* <div className='bgg'></div>
            <div className='bgg'></div> */}

      <Grid container sx={{}} direction="column" justifyContent="space-between">
        <Grid item lg={3}>
          <Container>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
              paddingTop="30px"
              paddingRight="20px"
            >
              <img className="logo" src={Logo}></img>

              {/* <Button
                onClick={handleOpen}
                sx={{
                  borderRadius: 10,
                  textTransform: "none",
                  background: "white",
                  padding: "20px",
                  color: "black",
                  height: 40,
                  fontSize: "12",
                  alignSelf: "center",
                }}
              >
                Register
              </Button> */}
            </Grid>
          </Container>
        </Grid>

        <Grid item lg={5} xs={7} paddingRight="10px">
          <>{Text}</>
        </Grid>
        <Grid
          item
          lg={1}
          alignSelf="end"
          paddingRight="20px"
          alignContent="center"
        >
          <Container>{Footer}</Container>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <section id="sewasew-form"></section>
        </Box>
      </Modal>
      {/* <img className="bgg" src={Background}></img>

      {isMatch ? (
        <>
          <img className="bgg" src={Gebetasmall}></img>
        </>
      ) : (
        <>
          <img className="bgg" src={Gebeta} sx={{ paddingLeft: "100px" }}></img>
        </>
      )} */}
    </Box>
  );
}

export default Header;
