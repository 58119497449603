import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Components/images/sewasewlogo.svg";
import { Container, Box, Grid, Typography } from "@mui/material";
import "../Components/App.css";
import Background from "../Components/images/background.png";

export const Terms = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        backgroundImage: `url(${Background})`,

        backgroundRepeat: "repeat",
        backgroundSize: "cover, cover",
        backgroundPosition: "fixed",
      }}
    >
      <Container>
        <Grid
          container
          sx={{}}
          direction="column"
          justifyContent="space-between"
        >
          <Grid item lg={3}>
            <Container>
              <Grid
                container
                alignContent="center"
                paddingTop="30px"
                paddingRight="0px"
              >
                <Box paddingTop="30px" paddingRight="0px">
                  <Link to="/">
                    <img className="logo" src={Logo}></img>
                  </Link>
                </Box>
              </Grid>
            </Container>
          </Grid>

          <Box
            contentEditable="false"
            dangerouslySetInnerHTML={{
              __html: `<h1 style="line-height: 115%; margin-bottom: 0.14in">
<font face="Cambria, serif">Terms and Conditions</font></h1>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Last
updated: April 12, 2022</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Please
read these terms and conditions carefully before using Our Service.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Interpretation
and Definitions</span></font></font></font></h2>
<h4 style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Interpretation</b></font></font></font></h4>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
words of which the initial letter is capitalized have meanings
defined under the following conditions. The following definitions
shall have the same meaning regardless of whether they appear in
singular or in plural.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Definitions</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">For
the purposes of these Terms and Conditions:</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Application</b></font><font face="Cambria, serif">
means the software program provided by the Company downloaded by You
on any electronic device, named sewasewmultimedia</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Application Store</b></font><font face="Cambria, serif">
means the digital distribution service operated and developed by
Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in
which the Application has been downloaded.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Affiliate</b></font><font face="Cambria, serif">
means an entity that controls, is controlled by or is under common
control with a party, where &quot;control&quot; means ownership of
50% or more of the shares, equity interest or other securities
entitled to vote for election of directors or other managing
authority.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Account</b></font><font face="Cambria, serif">
means a unique account created for You to access our Service or parts
of our Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Country</b></font><font face="Cambria, serif">
refers to: Ethiopia</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Company</b></font><font face="Cambria, serif">
(referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;
or &quot;Our&quot; in this Agreement) refers to 2F Capital PLC,
Namibia Street , Sheger building 6th floor ,.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Content</b></font><font face="Cambria, serif">
refers to content such as audio, video, text, images, or other
information that can be posted, uploaded, linked to or otherwise made
available by You, regardless of the form of that content.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Device</b></font><font face="Cambria, serif">
means any device that can access the Service such as a computer, a
cellphone or a digital tablet.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Feedback</b></font><font face="Cambria, serif">
means feedback, innovations or suggestions sent by You regarding the
attributes, performance, features and </font><font face="Cambria, serif">whatsoever</font><font face="Cambria, serif">
of our Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Free Trial</b></font><font face="Cambria, serif">
refers to a limited period of time that may be free when purchasing a
Subscription.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Goods</b></font><font face="Cambria, serif">
refer to the items offered for sale on the Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>In-app Purchase</b></font><font face="Cambria, serif">
refers to the purchase of a product, item, service or Subscription
made through the Application and subject to these Terms and
Conditions and/or the Application Store's own terms and conditions.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Orders</b></font><font face="Cambria, serif">
mean a request by You to purchase Goods from Us.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Promotions</b></font><font face="Cambria, serif">
refer to contests, sweepstakes or other promotions offered through
the Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Service</b></font><font face="Cambria, serif">
refers to the Application or the Website or both.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Subscriptions</b></font><font face="Cambria, serif">
refer to the services or access to the Service offered on a
subscription basis by the Company to You.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Terms and Conditions</b></font><font face="Cambria, serif">
(also referred as &quot;Terms&quot;) mean these Terms and Conditions
that form the entire agreement between You and the Company regarding
the use of the Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Third-party Social Media Service</b></font><font face="Cambria, serif">
means any services or content (including data, information, products
or services) provided by a third-party that may be displayed,
included or made available by the Service.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>Website</b></font><font face="Cambria, serif">
refers to </font><font face="Cambria, serif">sewasewmultimedia</font><font face="Cambria, serif">
, accessible from </font><a href="../../../../../../queued/0/c/9/0c9c486a-91a7-49b8-9ef6-1e2ac3f72362/www.sewasewmusic.com"><font face="Cambria, serif">www.sewasewmusic.com</font></a><font face="Cambria, serif">.</font></p>
<p style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<font face="Cambria, serif"><b>You</b></font><font face="Cambria, serif">
means the individual accessing or using the Service, or the company,
or other legal entity on behalf of which such individual is accessing
or using the Service, as applicable.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Acknowledgment</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">These
are the Terms and Conditions governing the use of this Service and
the agreement that operates between You and the Company. These Terms
and Conditions set out the rights and obligations of all users
regarding the use of the Service.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Your
access to and use of the Service is conditioned on Your acceptance of
and compliance with these Terms and Conditions. </font><font face="Cambria, serif"><font size="4" style="font-size: 16pt"><b>These
Terms and Conditions apply to all visitors, users and others who
access or use the Service.</b></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">By
accessing or using the Service You agree to be bound by these Terms
and Conditions. If You disagree with any part of these Terms and
Conditions then You may not access the Service.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font face="Cambria, serif">You
represent that you are </font><font face="Cambria, serif">over the
age of 18</font><font color="#222326"><font face="Cambria, serif"> or
</font></font><font color="#222326"><font face="Cambria, serif">be 14
years or older and have your parent or guardian's consent</font></font><font color="#222326"><font face="Cambria, serif">
to the Agreements as stated under the Federal Democratic Republic of
Ethiopia Revised Family Code (proclamation 213/2000), </font></font><font face="Cambria, serif">.
The Company does not permit those under 18 to use the Service.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Your
access to and use of the Service is also conditioned on Your
acceptance of and compliance with the Privacy Policy of the Company.
Our Privacy Policy describes Our policies and procedures on the
collection, use and disclosure of Your personal information when You
use the Application or the Website and tells You about Your privacy
rights and how the law protects You. Please read Our Privacy Policy
carefully before using Our Service.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Placing
Orders for Goods</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">By
placing an Order for Goods through the Service, You warrant that You
are legally capable of entering into binding contracts.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Your
Information</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You wish to place an Order for Goods available on the Service, You
may be asked to supply certain information relevant to Your Order
including, without limitation, Your name, Your email, Your phone
number, Your credit card number, the expiration date of Your credit
card, Your billing address, and Your shipping information.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
represent and warrant that: (i) You have the legal right to use any
credit or debit card(s) or other payment method(s) in connection with
any Order; and that (ii) the information You supply to us is true,
correct and complete.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">By
submitting such information, You grant us the right to provide the
information to payment processing third parties for purposes of
facilitating the completion of Your Order.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Order
Cancellation</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
reserve the right to refuse or cancel Your Order at any time for
certain reasons including but not limited to:</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Goods availability</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Errors in the description or prices for
Goods</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Errors in Your Order</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
reserve the right to refuse or cancel Your Order if fraud or an
unauthorized or illegal transaction is suspected.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>You’re
Order Cancellation Rights</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Any
Goods you purchase can only be returned in accordance with these
Terms and Conditions and Our Returns Policy.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Our
Returns Policy forms a part of these Terms and Conditions. </font><font face="Cambria, serif">Please
read our Returns Policy</font><font face="Cambria, serif"> to learn
more about your right to cancel Your Order.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Your
right to cancel an Order only applies to Goods that are returned in
the same condition as You received them. You should also include all
of the products instructions, documents and wrappings. Goods that are
damaged or not in the same condition as You received them or which
are worn simply beyond opening the original packaging will not be
refunded. You should therefore take reasonable care of the purchased
Goods while they are in Your possession.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
will reimburse You no later than 14 days from the day on which We
receive the returned Goods. We will use the same means of payment as
You used for the Order, and You will not incur any fees for such
reimbursement.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
will not have any right to cancel an Order for the supply of any of
the following Goods:</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">The supply of Goods made to Your
specifications or clearly personalized.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">The supply of Goods which according to
their nature are not suitable to be returned, deteriorate rapidly or
where the date of expiry is over.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">The supply of Goods which are not
suitable for return due to health protection or hygiene reasons and
were unsealed after delivery.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">The supply of Goods which are, after
delivery, according to their nature, inseparably mixed with other
items.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">The supply of digital content which is
not supplied on a tangible medium if the performance has begun with
Your prior express consent </font><strike><font face="Cambria, serif">and
You have acknowledged Your loss of cancellation right.</font></strike></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Availability,
Errors and Inaccuracies</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
are constantly updating Our offerings of Goods on the Service. The
Goods available on Our Service may be mispriced, described
inaccurately, or unavailable, and We may experience delays in
updating information regarding our Goods on the Service and in Our
advertising on other websites.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
cannot and do not guarantee the accuracy or completeness of any
information, including prices, product images, specifications,
availability, and services. We reserve the right to change or update
information and to correct errors, inaccuracies, or omissions at any
time without prior notice.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Prices
Policy</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company reserves the right to revise its prices at any time prior to
accepting an Order.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
prices quoted may be revised by the Company subsequent to accepting
an Order in the event of any occurrence affecting delivery caused by
government action, variation in customs duties, increased shipping
charges, higher foreign exchange costs and any other matter beyond
the control of the Company. In that event, You will have the right to
cancel Your Order.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Payments</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">All
Goods purchased are subject to a one-time payment. Payment can be
made through various payment methods we have available, such as Visa,
MasterCard, Affinity Card, American Express cards, </font><font face="Cambria, serif">telebirr,
cbe birr,</font><font face="Cambria, serif">  or other online payment
methods (PayPal, for example).</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Payment
cards (credit cards or debit cards) are subject to validation checks
and authorization by Your card issuer. If we do not receive the
required authorization, We will not be liable for any delay or
non-delivery of Your Order.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Subscriptions</span></font></font></font></h2>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Subscription
period</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Service or some parts of the Service are available only with a paid
Subscription. You will be billed in advance on a recurring and
periodic basis (such as daily, weekly, monthly or annually),
depending on the type of Subscription plan you select when purchasing
the Subscription.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">At
the end of each period, Your Subscription will automatically renew
under the exact same conditions unless You cancel it or the Company
cancels it.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Subscription
cancellations</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may cancel Your Subscription renewal either through Your </font><font face="Cambria, serif">Account
settings page or by contacting the Company</font><font face="Cambria, serif">.
You will not receive a refund for the fees You already paid for Your
current Subscription period and You will be able to access the
Service until the end of Your current Subscription period.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
the Subscription has been made through an In-app Purchase, You can
cancel the renewal of Your Subscription with the Application Store.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Billing</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
shall provide the Company with accurate and complete billing
information including full name, address, state, zip code, telephone
number, and a valid payment method information.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Should
automatic billing fail to occur for any reason, the Company will
issue an electronic invoice indicating that you must proceed
manually, within a certain deadline date, with the full payment
corresponding to the billing period as indicated on the invoice.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
the Subscription has been made through an In-app Purchase, all
billing is handled by the Application Store and is governed by the
Application Store's own terms and conditions.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Fee
Changes</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company, in its sole discretion and at any time, may modify the
Subscription fees. Any Subscription fee change will become effective
at the end of the then-current Subscription period.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company will provide You with reasonable prior notice of any change
in Subscription fees to give You an opportunity to terminate Your
Subscription before such change becomes effective.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Your
continued use of the Service after the Subscription fee change comes
into effect constitutes Your agreement to pay the modified
Subscription fee amount.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Refunds</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Except
when required by law, paid Subscription fees are non-refundable.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Certain
refund requests for Subscriptions may be considered by the Company on
a case-by-case basis and granted at the sole discretion of the
Company.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
the Subscription has been made through an In-app purchase, the
Application Store's refund policy will apply. If You wish to request
a refund, You may do so by contacting the Application Store directly.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Free
Trial</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company may, at its sole discretion, offer a Subscription with a Free
Trial for a limited period of time.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may be required to enter Your billing information in order to sign up
for the Free Trial.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You do enter Your billing information when signing up for a Free
Trial, You will not be charged by the Company until the Free Trial
has expired. On the last day of the Free Trial period, unless You
cancelled Your Subscription, You will be automatically charged the
applicable Subscription fees for the type of Subscription You have
selected.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">At
any time and without notice, the Company reserves the right to (i)
modify the terms and conditions of the Free Trial offer, or (ii)
cancel such Free Trial offer.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">In-app
Purchases</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Application may include In-app Purchases that allow you to buy
products, services or Subscriptions.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">More
information about how you may be able to manage In-app Purchases
using your Device may be set out in the Application Store's own terms
and conditions or in your Device's Help settings.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">In-app
Purchases can only be consumed within the Application. If you make a
In-app Purchase, that In-app Purchase cannot be cancelled after you
have initiated its download. In-app Purchases cannot be redeemed for
cash or other consideration or otherwise transferred.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
any In-app Purchase is not successfully downloaded or does not work
once it has been successfully downloaded, we will, after becoming
aware of the fault or being notified to the fault by You, investigate
the reason for the fault. We will act reasonably in deciding whether
to provide You with a replacement In-app Purchase or issue You with a
patch to repair the fault. In no event will We charge You to replace
or repair the In-app Purchase. In the unlikely event that we are
unable to replace or repair the relevant In-app Purchase or are
unable to do so within a reasonable period of time and without
significant inconvenience to You, We will authorize the Application
Store to refund You an amount up to the cost of the relevant In-app
Purchase. Alternatively, if You wish to request a refund, You may do
so by contacting the Application Store directly.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
acknowledge and agree that all billing and transaction processes are
handled by the Application Store from where you downloaded the
Application and are governed by that Application Store's own terms
and conditions.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
you have any payment related issues with In-app Purchases, then you
need to contact the Application Store directly.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Promotions</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Any
Promotions made available through the Service may be governed by
rules that are separate from these Terms.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You participate in any Promotions, please review the applicable rules
as well as our Privacy policy. If the rules for a Promotion conflict
with these Terms, the Promotion rules will apply.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">User
Accounts</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">When
You create an account with Us, You must provide Us information that
is accurate, complete, and current at all times. Failure to do so
constitutes a breach of the Terms, which may result in immediate
termination of Your account on Our Service.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
are responsible for safeguarding the password that You use to access
the Service and for any activities or actions under Your password,
whether Your password is with Our Service or a Third-Party Social
Media Service.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
agree not to disclose Your password to any third party. You must
notify Us immediately upon becoming aware of any breach of security
or unauthorized use of Your account.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may not use as a username the name of another person or entity or
that is not lawfully available for use, a name or trademark that is
subject to any rights of another person or entity other than You
without appropriate authorization, or a name that is otherwise
offensive, vulgar or obscene.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Content</span></font></font></font></h2>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Your
Right to Post Content</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Our
Service may allows You to post Content. You are responsible for the
Content that You post to the Service, including its legality,
reliability, and appropriateness.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">By
posting Content to the Service, You grant Us the right and license to
use, modify, publicly perform, publicly display, reproduce, and
distribute such Content on and through the Service. You retain any
and all of Your rights to any Content You submit, post or display on
or through the Service and You are responsible for protecting those
rights. You agree that this license includes the right for Us to make
Your Content available to other users of the Service, who may also
use Your Content subject to these Terms.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
represent and warrant that: (i) the Content is Yours (You own it) or
You have the right to use it and grant Us the rights and license as
provided in these Terms, and (ii) the posting of Your Content on or
through the Service does not violate the privacy rights, publicity
rights, copyrights, contract rights or any other rights of any
person.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Content
Restrictions</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company is not responsible for the content of the Service's users.
You expressly understand and agree that You are solely responsible
for the Content and for all activity that occurs under your account,
whether done so by You or any third person using Your account.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may not transmit any Content that is unlawful, offensive, upsetting,
intended to disgust, threatening, libelous, defamatory, obscene or
otherwise objectionable. Examples of such objectionable Content
include, but are not limited to, the following:</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Unlawful or promoting unlawful activity.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Defamatory, discriminatory, or
mean-spirited content, including references or commentary about
religion, race, sexual orientation, gender, national/ethnic origin,
or other targeted groups.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Spam, machine – or randomly –
generated, constituting unauthorized or unsolicited advertising,
chain letters, any other form of unauthorized solicitation, or any
form of lottery or gambling.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Containing or installing any viruses,
worms, malware, trojan horses, or other content that is designed or
intended to disrupt, damage, or limit the functioning of any
software, hardware or telecommunications equipment or to damage or
obtain unauthorized access to any data or other information of a
third person.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Infringing on any proprietary rights of
any party, including patent, trademark, trade secret, copyright,
right of publicity or other rights.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Impersonating any person or entity
including the Company and its employees or representatives.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Violating the privacy of any third
person.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">False information and features.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company reserves the right, but not the obligation, to, in its sole
discretion, determine whether or not any Content is appropriate and
complies with this Terms, refuse or remove this Content. The Company
further reserves the right to make formatting and edits and change
the manner of any Content. The Company can also limit or revoke the
use of the Service if You post such objectionable Content. As the
Company cannot control all content posted by users and/or third
parties on the Service, you agree to use the Service at your own
risk. You understand that by using the Service You may be exposed to
content that You may find offensive, indecent, incorrect or
objectionable, and You agree that under no circumstances will the
Company be liable in any way for any content, including any errors or
omissions in any content, or any loss or damage of any kind incurred
as a result of your use of any content.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Content
Backups</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Although
regular backups of Content are performed, the Company does not
guarantee there will be no loss or corruption of data.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Corrupt
or invalid backup points may be caused by, without limitation,
Content that is corrupted prior to being backed up or that changes
during the time a backup is performed.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company will provide support and attempt to troubleshoot any known or
discovered issues that may affect the backups of Content. But You
acknowledge that the Company has no liability related to the
integrity of Content or the failure to successfully restore Content
to a usable state.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
agree to maintain a complete and accurate copy of any Content in a
location independent of the Service.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Copyright
Policy</span></font></font></font></h2>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Intellectual
Property Infringement</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
respect the intellectual property rights of others. It is Our policy
to respond to any claim that Content posted on the Service infringes
a copyright or other intellectual property infringement of any
person.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You are a copyright owner, or authorized on behalf of one, and You
believe that the copyrighted work has been copied in a way that
constitutes copyright infringement that is taking place through the
Service, You must submit Your notice in writing to the attention of
our copyright agent via email at info@sewasewmultimediamusic.com and
include in Your notice a detailed description of the alleged
infringement.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may be held accountable for damages (including costs and attorneys'
fees) for misrepresenting that any Content is infringing Your
copyright.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>DMCA
Notice and DMCA Procedure for Copyright Infringement Claims</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
may submit a notification pursuant to the Digital Millennium
Copyright Act (DMCA) by providing our Copyright Agent with the
following information in writing (see 17 U.S.C 512(c)(3) for further
detail):</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">An electronic or physical signature of
the person authorized to act on behalf of the owner of the
copyright's interest.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">A description of the copyrighted work
that You claim has been infringed, including the URL (i.e., web page
address) of the location where the copyrighted work exists or a copy
of the copyrighted work.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Identification of the URL or other
specific location on the Service where the material that You claim is
infringing is located.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">Your address, telephone number, and email
address.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">A statement by You that You have a good
faith belief that the disputed use is not authorized by the copyright
owner, its agent, or the law.</font></p>
<p style="line-height: 115%; margin-left: 0.25in; text-indent: -0.25in; margin-bottom: 0.14in">
<font face="Cambria, serif">A statement by You, made under penalty of
perjury, that the above information in Your notice is accurate and
that You are the copyright owner or authorized to act on the
copyright owner's behalf.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
can contact our copyright agent via email at
info@sewasewmultimediamusic.com. Upon receipt of a notification, the
Company will take whatever action, in its sole discretion, it deems
appropriate, including removal of the challenged content from the
Service.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Intellectual
Property</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Service and its original content (excluding Content provided by You
or other users), features and functionality are and will remain the
exclusive property of the Company and its licensors.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Service is protected by copyright, trademark, and other laws of both
the Country and foreign countries.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Our
trademarks and trade dress may not be used in connection with any
product or service without the prior written consent of the Company.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Your
Feedback to Us</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
assign all rights, title and interest in any Feedback You provide the
Company. If for any reason such assignment is ineffective, You agree
to grant the Company a non-exclusive, perpetual, irrevocable, royalty
free, worldwide right and license to use, reproduce, disclose,
sub-license, distribute, modify and exploit such Feedback without
restriction.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Links
to Other Websites</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Our
Service may contain links to third-party web sites or services that
are not owned or controlled by the Company.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Company has no control over, and assumes no responsibility for, the
content, privacy policies, or practices of any third party web sites
or services. You further acknowledge and agree that the Company shall
not be responsible or liable, directly or indirectly, for any damage
or loss caused or alleged to be caused by or in connection with the
use of or reliance on any such content, goods or services available
on or through any such web sites or services.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
strongly advise You to read the terms and conditions and privacy
policies of any third-party web sites or services that You visit.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Termination</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
may terminate or suspend Your Account immediately, without prior
notice or liability, for any reason whatsoever, including without
limitation if You breach these Terms and Conditions.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Upon
termination, Your right to use the Service will cease immediately. If
You wish to terminate Your Account, You may simply discontinue using
the Service.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Limitation
of Liability</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Notwithstanding
any damages that You might incur, the entire liability of the Company
and any of its suppliers under any provision of this Terms and Your
exclusive remedy for all of the foregoing shall be limited to the
amount actually paid by You through the Service or 100 USD if You
haven't purchased anything through the Service.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">To
the maximum extent permitted by applicable law, in no event shall the
Company or its suppliers be liable for any special, incidental,
indirect, or consequential damages whatsoever (including, but not
limited to, damages for loss of profits, loss of data or other
information, for business interruption, for personal injury, loss of
privacy arising out of or in any way related to the use of or
inability to use the Service, third-party software and/or third-party
hardware used with the Service, or otherwise in connection with any
provision of this Terms), even if the Company or any supplier has
been advised of the possibility of such damages and even if the
remedy fails of its essential purpose.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Some
states do not allow the exclusion of implied warranties or limitation
of liability for incidental or consequential damages, which means
that some of the above limitations may not apply. In these states,
each party's liability will be limited to the greatest extent
permitted by law.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">&quot;AS
IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
and with all faults and defects without warranty of any kind. To the
maximum extent permitted under applicable law, the Company, on its
own behalf and on behalf of its Affiliates and its and their
respective licensors and service providers, expressly disclaims all
warranties, whether express, implied, statutory or otherwise, with
respect to the Service, including all implied warranties of
merchantability, fitness for a particular purpose, title and
non-infringement, and warranties that may arise out of course of
dealing, course of performance, usage or trade practice. Without
limitation to the foregoing, the Company provides no warranty or
undertaking, and makes no representation of any kind that the Service
will meet Your requirements, achieve any intended results, be
compatible or work with any other software, applications, systems or
services, operate without interruption, meet any performance or
reliability standards or be error free or that any errors or defects
can or will be corrected.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Without
limiting the foregoing, neither the Company nor any of the company's
provider makes any representation or warranty of any kind, express or
implied: (i) as to the operation or availability of the Service, or
the information, content, and materials or products included thereon;
(ii) that the Service will be uninterrupted or error-free; (iii) as
to the accuracy, reliability, or currency of any information or
content provided through the Service; or (iv) that the Service, its
servers, the content, or e-mails sent from or on behalf of the
Company are free of viruses, scripts, trojan horses, worms, malware,
timebombs or other harmful components.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Some
jurisdictions do not allow the exclusion of certain types of
warranties or limitations on applicable statutory rights of a
consumer, so some or all of the above exclusions and limitations may
not apply to You. But in such a case the exclusions and limitations
set forth in this section shall be applied to the greatest extent
enforceable under applicable law.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 26pt"><span style="letter-spacing: 0.3pt">Governing
Law</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">The
laws of the Country, excluding its conflicts of law rules, shall
govern this Terms and Your use of the Service. Your use of the
Application may also be subject to other local, state, national, or
international laws.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><br/>
<br/>

</p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Disputes
Resolution</span></font></font></font></h2>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font face="Cambria, serif">If
You have any concern or dispute about the Service, You agree to first
try to resolve the dispute informally by contacting the Company. </font><font color="#222326"><font face="Cambria, serif">You
and SEWASEWMULTIMEDIA agree that any dispute, claim, or controversy
between you and SEWASEWMULTIMEDIA arising in connection with or
relating in any way to these Agreements or to your relationship with
SEWASEWMULTIMEDIA as a user of the Service (whether based in
contract, tort, statute, fraud, misrepresentation, or any other legal
theory, and whether the claims arise during or after the termination
of the Agreements) will be determined by mandatory binding individual
(not class) arbitration with the rules and mechanisms of Arbitration
and Conciliation, working procedure Proclamation 1237/2021. </font></font>
</p>
<p style="line-height: 115%; margin-bottom: 0.14in"><br/>
<br/>

</p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">For
European Union (EU) Users</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You are a European Union consumer, you will benefit from any
mandatory provisions of the law of the country in which you are
resident in.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">United
States Federal Government End Use Provisions</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
You are a U.S. federal government end user, our Service is a
&quot;Commercial Item&quot; as that term is defined at 48 C.F.R.
§2.101.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">United
States Legal Compliance</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">You
represent and warrant that (i) You are not located in a country that
is subject to the United States government embargo, or that has been
designated by the United States government as a &quot;terrorist
supporting&quot; country, and (ii) You are not listed on any United
States government list of prohibited or restricted parties.</font></p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Severability
and Waiver</span></font></font></font></h2>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Severability</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">If
any provision of these Terms is held to be unenforceable or invalid,
such provision will be changed and interpreted to accomplish the
objectives of such provision to the greatest extent possible under
applicable law and the remaining provisions will continue in full
force and effect.</font></p>
<p style="line-height: 115%; page-break-inside: avoid; margin-top: 0.14in; margin-bottom: 0in; page-break-after: avoid">
<font color="#3D0000"><font face="Calibri, serif"><font size="3" style="font-size: 16pt"><b>Waiver</b></font></font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">Except
as provided herein, the failure to exercise a right or to require
performance of an obligation under these Terms shall not effect a
party's ability to exercise such right or require such performance at
any time thereafter nor shall the waiver of a breach constitute a
waiver of any subsequent breach.</font></p>
<h2 class="western"><font size="6" style="font-size: 23pt"><u>Limitation
and time for filing</u></font></h2>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">YOU
AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR
DISSATISFACTION WITH THE SEWASEWMULTIMEDIA SERVICE IS TO UNINSTALL
ANY SEWASEWMULTIMEDIA SOFTWARE AND TO STOP USING THE
SEWASEWMULTIMEDIA SERVICE. YOU AGREE THAT SEWASEWMULTIMEDIA HAS NO
OBLIGATION OR LIABILITY ARISING FROM OR RELATED TO THIRD PARTY
APPLICATIONS OR THE CONTENT THEREOF MADE AVAILABLE THROUGH OR IN
CONNECTION WITH THE SEWASEWMULTIMEDIA  SERVICE, AND WHILE YOUR
RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY
SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, YOUR SOLE AND EXCLUSIVE
REMEDY, AS WITH RESPECT TO SEWASEWMULTIMEDIA, FOR ANY PROBLEMS OR
DISSATISFACTION WITH THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF,
IS TO UNINSTALL AND/OR STOP USING ANY SUCH THIRD PARTY APPLICATIONS.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">IN
NO EVENT WILL SEWASEWMULTIMEDIA, ITS OFFICERS, SHAREHOLDERS,
EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS,
ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR;</font></font></p>
<ol>
	<ol>
		<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
		<font color="#222326"><font face="Cambria, serif">ANY INDIRECT,
		SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES;
		</font></font>
		</p>
		<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
		<font color="#222326"><font face="Cambria, serif">ANY LOSS OF USE,
		DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL
		CASES ARISING OUT OF THE USE OF OR INABILITY TO USE THE
		SEWASEWMULTIMEDIA SERVICE, DEVICES THIRD PARTY APPLICATIONS, OR
		THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY,
		WITHOUT REGARD TO WHETHER SEWASEWMULTIMEDIA HAS BEEN WARNED OF THE
		POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS
		ESSENTIAL PURPOSE; OR </font></font>
		</p>
		<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
		<font color="#222326"><font face="Cambria, serif">AGGREGATE
		LIABILITY FOR ALL CLAIMS RELATING TO THE SEWASEWMULTIMEDIA SERVICE,
		THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT MORE
		THAN THE AMOUNTS PAID BY YOU TO SEWASEWMULTIMEDIA DURING THE PRIOR
		6(SIX) MONTHS IN QUESTION.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">YOU
AGREE THAT ANY CLAIM AGAINST SEWASEWMULTIMEDIA MUST BE COMMENCED (BY
FILING A DEMAND FOR ARBITRATION OR FILING AN INDIVIDUAL ACTION WITHIN
ONE (1) YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS
OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION, OR DEFAULT GIVING
RISE TO THE CLAIM; AND THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY
CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">THIS
SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in; padding-top:0.4in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Third
party rights</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">You
acknowledge and agree that the owners of the Content and certain
distributors (such as app store providers) are intended beneficiaries
of the Agreements and have the right to enforce the Agreements
directly against you. Other than as set out in this section, the
Agreements are not intended to grant rights to anyone except you and
SEWASEWMULTIMEDIA and in no event shall the Agreements create any
third party beneficiary rights. Furthermore, the rights to terminate,
rescind, or agree to any variation, waiver, or settlement of the
Agreements are not subject to the consent of any other person.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Entire
Agreement</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">Other
than as stated in this section or as explicitly agreed upon in
writing between you and SEWASEWMULTIMEDIA the Agreements constitute
all the terms and conditions agreed upon between you and
SEWASEWMULTIMEDIA and supersede any prior agreements in relation to
the subject matter of these Agreements, whether written or oral.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">Certain
aspects of your use of the SEWASEWMULTIMEDIA Service may be governed
by additional agreements. That could include, for example, access to
the SEWASEWMULTIMEDIA Service as a result of a gift card, free or
discounted Trials, or together with other services. When you are
presented with an offer for such aspects of your use, you will be
presented with any related additional agreement, and you may have an
opportunity to agree to additional terms. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">To
the extent that there is any irreconcilable conflict between any
additional terms and these Terms, the additional terms shall prevail.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Assignment</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">SEWASEWMULTIMEDIA
may assign the Agreements and any of its rights under the Agreements,
in whole or in part, and SEWASEWMULTIMEDIA may delegate any of its
obligations under the Agreements. You may not assign the Agreements,
in whole or in part, nor transfer or sub-license your rights under
the Agreements, to any third party.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Indemnifications</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">You
agree to indemnify and hold SEWASEWMULTIMEDIA harmless from and
against all damages, losses, and expenses of any kind (including
reasonable attorney fees and costs) arising out of or related to: </font></font>
</p>
<ol>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
	<font color="#222326"><font face="Cambria, serif">Your breach of the
	Agreements or any one of them; </font></font>
	</p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
	<font color="#222326"><font face="Cambria, serif">Any User Content
	you post or otherwise contribute; </font></font>
	</p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
	<font color="#222326"><font face="Cambria, serif">Any activity in
	which you engage on or through the SEWASEWMULTIMEDIA Service; and </font></font>
	</p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0.17in">
	<font color="#222326"><font face="Cambria, serif">Your violation of
	any law or the rights of a third party.</font></font></p>
</ol>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Governing
law / Jurisdiction</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">The
Agreements (and any non-contractual disputes/claims arising out of or
in connection with them) are subject to the laws of the Federal
Democratic Republic of Ethiopia, without regard to choice or
conflicts of law principles.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">You
and SEWASEWMULTIMEDIA agree to the jurisdiction of the Federal
Democratic Republic of Ethiopia Federal Courts to resolve any
dispute, claim, or controversy that relates to or arises in
connection with the Agreements (and any non-contractual
disputes/claims relating to or arising in connection with them) and
is not subject to mandatory arbitration. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>Dispute
resolution and arbitration</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">You
and SEWASEWMULTIMEDIA agree that any dispute, claim, or controversy
between you and SEWASEWMULTIMEDIA arising in connection with or
relating in any way to these Agreements or to your relationship with
SEWASEWMULTIMEDIA as a user of the Service (whether based in
contract, tort, statute, fraud, misrepresentation, or any other legal
theory, and whether the claims arise during or after the termination
of the Agreements) will be determined by mandatory binding individual
(not class) arbitration with the rules and mechanisms of Arbitration
and Conciliation, working procedure Proclamation 1237/2021. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.08in"><font color="#3D0000"><font face="Calibri Light, serif"><font size="6" style="font-size: 23pt"><u><b>No
class or representative proceedings; class action waiver</b></u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">YOU
AND SEWASEWMULTIMEDIA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION.
Unless both you and SEWASEWMULTIMEDIA agree, no arbitrator or court
may consolidate more than one person's claims or otherwise preside
over any form of a representative or class proceeding. The arbitrator
may award injunctive relief only in favor of the individual party
seeking relief and only to the extent necessary to provide relief
warranted by that party's individual claim. If a court decides that
applicable law precludes enforcement of any of this paragraph's
limitations as to a particular claim for relief, then that claim (and
only that claim) must be severed from the arbitration and may be
brought in court.</font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><br/>
<br/>

</p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Translation
and Interpretation</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">These
Terms and Conditions may have been translated if We have made them
available to You on our Service. You agree that the original English
text shall prevail in the case of a dispute.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.17in"><font color="#222326"><font face="Cambria, serif">As
used in these Terms, the words &quot;include&quot; and &quot;including,&quot;
and variations thereof, will be deemed to be followed by the words
&quot;without limitation.&quot;</font></font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><a name="_GoBack"></a>
<br/>
<br/>

</p>
<h2 style="line-height: 100%; margin-bottom: 0.21in; border-top: none; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in; padding-left: 0in; padding-right: 0in">
<font color="#3D0000"><font face="Calibri, serif"><font size="6" style="font-size: 23pt"><span style="letter-spacing: 0.3pt">Changes
to These Terms and Conditions</span></font></font></font></h2>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">We
reserve the right, at Our sole discretion, to modify or replace these
Terms at any time. If a revision is material We will make reasonable
efforts to provide at least 30 days' notice prior to any new terms
taking effect. What constitutes a material change will be determined
at Our sole discretion.</font></p>
<p style="line-height: 115%; margin-bottom: 0.14in"><font face="Cambria, serif">By
continuing to access or use Our Service after those revisions become
effective, You agree to be bound by the revised terms. If You do not
agree to the new terms, in whole or in part, please stop using the
website and the Service.</font></p>
	<h1 id="calibre_link-46" class="block_7" style=" color:#3D0000; border-bottom: 1.00pt solid #3D0000; border-left: none; border-right: none; padding-top: 0.4in; padding-bottom: 0.1in;">Contact Us</h1>
	<p class="block_4">&nbsp;</p>
	<p class="block_6">If you have any questions about this Privacy Policy, You can contact us:</p>
	<p class="block_4">&nbsp;</p>
	<div class="calibre1">
	<div class="block_10"><span class="bullet_1">●&nbsp;</span><span class="calibre2">By visiting this page on our website: <span class="text_3">www.sewasewmusic.com/support</span></span></div>
	<div class="block_10"><span class="bullet_1">●&nbsp;</span><span class="calibre2">By sending us an email: <span class="text_3">info@sewasewmusic.com</span></span></div>
</div>
	<p class="block_4">&nbsp;</p>

</div>




`,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          lg={1}
          alignSelf="end"
          paddingRight="20px"
          alignContent="center"
        >
          <Container></Container>
        </Grid>
      </Container>
    </div>
  );
};
